import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import _ from 'lodash'

function renderImage (images, props) {
  const file = images.edges.find(
    image => `/img/${image.node.relativePath}` === props.src
  )
  if (!_.get(file, 'node.childImageSharp.fluid')) {
    console.warn('Did not find image', props, images)
    return null
  }
  return <Img fluid={file.node.childImageSharp.fluid} />
}

export const AllImg = function (props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 400, quality: 85) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}
      render={({ images }) => renderImage(images, props)}
    />
  )
}
