import React, { Component } from 'react'
import { Link } from 'gatsby'
import config from '../../config'
import Helmet from 'react-helmet'
import PostCard from '../components/PostCard'
import Layout from '../components/Layout'

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/blog/${props.url}`} className='button is-rounded'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='button is-rounded'>
        {props.text}
      </span>
    )
  }
}

const OtherPlacesPub = () => {
  return (
    <div className='section'>
      <h3 className='title is-size-3 '>
        Other places these have been published
      </h3>
      <ul>
        <li>
          SourceCon (12/19/2019): <a href='https://www.sourcecon.com/sourcing-on-dev-to/' target='_blank' rel='noopener noreferrer'>
          Sourcing on Dev.to
          </a>
        </li>
        <li>
          ERE.net (12/7/2019):{' '}
          <a
            href='https://www.ere.net/software-engineers-do-not-leave-their-jobs-for-the-reason-you-think-they-do/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Software Engineers Do Not Leave Their Jobs for the Reason You Think
            They Do
          </a>
        </li>
        <li>
          Recruiter.com (11/19/2019):{' '}
          <a
            href='https://www.recruiter.com/i/hiring-a-software-engineer-look-out-for-these-resume-red-flags/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Hiring a Software Engineer? Look Out for These Resume Red Flags
          </a>
        </li>
        <li>
          SourceCon (11/8/2019):{' '}
          <a
            href='https://www.sourcecon.com/reading-github-profiles-when-youre-not-an-engineer/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Reading Github Profiles When You’re Not An Engineer
          </a>
        </li>
        <li>
          Dev.to:{' '}
          <a
            href='https://dev.to/ard/message-queues-and-kafka-explained-in-plain-english-550g'
            target='_blank'
            rel='noopener noreferrer'
          >
            Message Queues and Kafka Explained in Plain English
          </a>
        </li>
        <li>
          Dev.to:{' '}
          <a
            href='https://dev.to/ard/docker-explained-visually-for-the-non-technical-419g'
            target='_blank'
            rel='noopener noreferrer'
          >
            Docker explained visually for the non-technical
          </a>
        </li>
      </ul>
      <hr />
    </div>
  )
}

const GreatestHits = () => {
  return (
    <div className='section'>
      <h3 className='title is-size-3 '>Greatest Hits</h3>
      <ul>
        <li>
          <Link to='/blog/12-subject-lines-to-get-software-engineers-to-hit-reply/'>
            12 Subject Lines To Get Software Engineers To Reply
          </Link>
        </li>
        <li>
          <Link to='/blog/when-everyone-started-using-impressed-as-an-opener/'>
            When everyone suddenly started using "Impressed" as an opener
          </Link>
        </li>
        <li>
          <Link to='/blog/rating-cold-emails-from-recruiters-as-a-developer/'>
            Rating Cold Emails As A Developer
          </Link>
        </li>
        <li>
          <Link to='/blog/why-dont-developers-respond-to-recruiters-a-survey/'>
            Why don't developers respond to recruiters? (a survey)
          </Link>
        </li>
      </ul>
      <hr />
    </div>
  )
}

export default class BlogPage extends Component {
  render () {
    const { pageContext } = this.props
    const { group, index, first, last } = pageContext
    const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
    const nextUrl = (index + 1).toString() + '/'

    const websiteSchemaOrgJSONLD = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    }

    return (
      <Layout>
        <Helmet>
          <title>
            ITeach Recruiters blog: shedding light on the gap between developers
            and recruiters
          </title>
          {/* Schema.org tags */}
          <script type='application/ld+json'>
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>
        <section className='hero is-primary is-bold'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns'>
                <div className='column is-10 is-offset-1'>
                  <div className='section'>
                    <h1 className='title has-text-white is-size-1'>Blog</h1>
                    <h2 className='description has-text-white is-size-3'>
                      ITeach Recruiters blog: shedding light on the gap between
                      developers and recruiters
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-5 is-offset-1'>
                <OtherPlacesPub />
              </div>
              <div className='column is-5'>
                <GreatestHits />
              </div>
            </div>
          </div>
          <PostCard posts={group} />
          <section className='section'>
            <div className='buttons is-centered'>
              <PaginationLink
                test={first}
                url={previousUrl}
                text='Previous Page'
              />
              <PaginationLink test={last} url={nextUrl} text='Next Page' />
            </div>
          </section>
        </section>
      </Layout>
    )
  }
}
