import React from 'react'
import { Link } from 'gatsby'
import { AllImg } from '../common/all-image-small'
import styled from 'styled-components'

const CardBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Card = styled.div`
  border: 1px solid #eaecee;
  padding: 1rem 2rem;
  max-width: 500px !important;
  min-width: 320px;
  width: 30%;
  margin: 1rem;

  @media only screen and (max-width: 750px) {
    width: 90%;
  }
`

const CardContent = ({ post }) => {
  return (
    <Card className='card'>
      <div className='card-image'>
        <figure className='image has-text-centered'>
          <AllImg src={post.frontmatter.cover} alt='Blog cover image' />
        </figure>
      </div>
      <div className='card-content'>
        <div className='media'>
          <div className='media-content'>
            <Link className='has-text-primary' to={post.fields.slug}>
              <p className='title is-4 has-text-info'>
                {post.frontmatter.title}
              </p>
            </Link>
            <p className='subtitle is-6 has-text-primary'>
              <small>{post.frontmatter.date}</small>
              <br />
              {post.frontmatter.author ? (
                <small>Author: {post.frontmatter.author.id}</small>
              ) : null}
            </p>
          </div>
        </div>

        <div className='content'>
          {post.excerpt}
          <br />
          <Link className='button is-small' to={post.fields.slug}>
            Keep Reading →
          </Link>
        </div>
      </div>
    </Card>
  )
}

const PostCard = ({ posts }) => {
  return (
    <div className='container'>
      <CardBox>
        {posts
          .filter(post => post.node.frontmatter.templateKey === 'article-page')
          .map(({ node: post }) => (
            <CardContent post={post} key={post.id} />
          ))}
      </CardBox>
    </div>
  )
}

export default PostCard
